.App {
  text-align: center;
  align-items: flex-end;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.filter-result-container {
  display: grid;
  gap: 20px;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: auto;
}

@media screen and (max-width: 2200px) {
  .filter-result-container {
    gap: 10px;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1700px) {
  .filter-result-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1200px) {
  .filter-result-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 899px) {
  .filter-result-container {
    gap: 5px;
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.filter-result-container img {
  width: 100%;
  object-fit: cover;
}

/* Full-width image */
.large {
  flex: 1 1 100%;
  max-width: 100%;
}

.collection-item {
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 899px) {
    border-radius: 0;
    height: 60vw;

    &:first-child {
      height: 80vw;
    }
  }
}

@media screen and (max-width: 1500px) {
  .collection-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1100px) {
  .collection-container {
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 899px) {
  .collection-container {
    grid-template-columns: 1fr;
  }
}

/* Gradient overlay */
.collection-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  z-index: 1;
}

a.item {
  text-decoration: none;
}

.collection-item h3 {
  color: #fff;
  position: absolute;
  z-index: 90;
  bottom: 0;
}

.page-nav {
  display: flex;
  justify-content: center;
  gap: 10px;
  color: var(--text-color);
  width: 100%;
  padding: 20px;
  align-items: center;
  gap: 20px;
  max-width: calc(100% - 40px);
  font-size: 14px;
  flex-wrap: wrap;

  @media screen and (max-width: 899px) {
    margin-top: 50px;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    display: none;
  }
}

.page-nav a {
  color: var(--text-color);
  text-decoration: none;
}

.page-nav a.active {
  font-weight: 600;
  font-size: 16px;
}

.page-nav h2 {
  margin: 0;
  align-self: start;
  flex: 1 1 auto;
  text-align: start;
}

.item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image scales and covers the container while maintaining its aspect ratio */
}

.fslightbox-container {
  backdrop-filter: blur(6px);
}

img.fslightboxs {
  width: 100% !important;
  height: 100% !important;
}

.fslightbox-slide-btn-previous-container,
.fslightbox-slide-btn-next-container {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.fslightbox-toolbar > .fslightbox-flex-centered:first-of-type {
}

.fslightbox-slide-number-container {
  z-index: 999;
}

.fslightbox-absoluted > div {
  max-width: 100vw;
  max-height: 100vw;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fslightbox-absoluted img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;

  @media screen and (max-width: 1200px) {
    object-fit: cover;
  }
}

.filter-wrapper {
  position: fixed; /* Make the filter-wrapper fixed */
  top: 0; /* Adjust this to control the vertical position */
  left: 0; /* Adjust this to control the horizontal position */
  width: 240px; /* Set a fixed width or adjust as needed */
  height: calc(100vh - 100px);
  background: var(--sidebar-background);
  padding: 50px 40px;
  margin: 0;
  overflow-y: auto; /* Allow scrolling if content exceeds viewport height */
}

/* Adjust content area to avoid overlap */
.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 1 1 100%;
  justify-content: flex-end;
  width: calc(100% - 320px);
  
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}


.filter-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filter-area div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-color) !important;
  max-width: 100%;
}
.filter-area h3 {
  color: var(--text-color)!important;
}

.filter-area label {
  display: flex;
  gap: 5px;
  align-items: center;
  text-align: left;
}
.filter-area input[type='checkbox'] {
  width: 16px;
  height: 16px;
}

.MuiSlider-markLabel {
  color: var(--text-color)!important;
}

.MuiSlider-root {
  max-width: calc(100% - 38px);
  margin: auto;
}

h3 {
  padding: 20px 0;
  margin: 0;
}

.MuiCheckbox-root svg path{
  fill: var(--checkbox-color);
}