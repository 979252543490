.App {
  text-align: center;
  max-width: 2400px;
  margin: 0 auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.flex-container {
  display: grid;
  gap: 20px;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr)); /* Default layout */
  grid-auto-rows: auto;

  &.small {
    grid-template-columns: repeat(5, 1fr); /* 5 images per row */
  }

  &.medium {
    grid-template-columns: repeat(3, 1fr); /* 3 images per row */
  }

  &.large {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row */
  }

  &.full {
    grid-template-columns: repeat(1, 1fr); /* 2 images per row */
  }
}


@media screen and (max-width: 1399px) {
  .flex-container {
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1199px) {
  .flex-container {
    grid-template-columns: repeat(2, 1fr);

    &.small {
      grid-template-columns: repeat(4, 1fr);
    }

    &.medium {
      grid-template-columns: repeat(3, 1fr);
    }

    &.large {
      grid-template-columns: repeat(2, 1fr);
    }

    &.full {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 899px) {
  .flex-container {
    grid-template-columns: repeat(2, 1fr);

    &.small, &.medium, &.large, &.full {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 599px) {
  .flex-container {
    gap: 5px;
    grid-template-columns: 1fr;
  
    &.small, &.medium, &.large, &.full {
      grid-template-columns: 1fr;
    }
  }
}

.flex-container img {
  width: 100%;
  object-fit: cover;
}

.collection-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 67px;
}

/* Full-width image */
.large {
  flex: 1 1 100%;
  max-width: 100%;
}

/* Half-width images */
.item {
  position: relative;
  width: 100%;
  padding-bottom: 62.5%; /* Maintain a 16:10 aspect ratio */
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .info {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    color: white;
    justify-content: flex-start;
    padding: 15px;
    transition: all 0.3s ease;
    opacity: 0; /* Initially hidden */
    transform: translateY(100%); /* Position below the container */
    z-index: 900;
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    .info {
      opacity: 1; /* Fully visible on hover */
      transform: translateY(0); /* Slide into view */
    }

    &:after {
      opacity: 1; /* Make shadow visible */
    }

    @media screen and (max-width: 599px) {
      .info {
        display: none !important;
      }
    }
  }

  /* Ensure the :after pseudo-element is hidden initially */
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    z-index: 1;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Opacity transition for the shadow effect */
  }
}


@media screen and (max-width: 899px) {
  .item {
    border-radius: 0;
    height: auto;
    padding-bottom: 70%;

    &:first-child {
      padding-bottom: 90%;
    }
  }
}

.featured-item {
  position: relative;
  width: 100%;
  height: 600px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.collection-item {
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 899px) {
    border-radius: 0;
    height: 60vw;

    &:first-child {
      height: 80vw;
    }
  }
}

.collection-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

@media screen and (max-width: 1500px) {
  .collection-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1100px) {
  .collection-container {
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 899px) {
  .collection-container {
    grid-template-columns: 1fr;
  }
}

/* Gradient overlay */
.collection-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  z-index: 1;
}

a.item {
  text-decoration: none;
}

.collection-item h3 {
  color: #fff;
  position: absolute;
  z-index: 90;
  bottom: 0;
  font-size: 20px;
}

.page-nav {
  display: flex;
  justify-content: center;
  gap: 10px;
  color: var(--text-color);
  width: 100%;
  padding: 20px;
  align-items: center;
  gap: 20px;
  max-width: calc(100% - 40px);
  font-size: 14px;
  flex-wrap: wrap;

  @media screen and (max-width: 899px) {
    margin-top: 50px;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
    display: none;
  }
}

.page-nav a {
  color: var(--text-color);
  text-decoration: none;
}

.page-nav a.active {
  font-weight: 600;
  font-size: 16px;
}

.page-nav h2 {
  margin: 0;
  align-self: start;
  flex: 1 1 auto;
  text-align: start;
}

.item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image scales and covers the container while maintaining its aspect ratio */
}

.fslightbox-container {
  backdrop-filter: blur(6px);
}

img.fslightboxs {
  width: 100% !important;
  height: 100% !important;
}

.fslightbox-slide-btn-previous-container,
.fslightbox-slide-btn-next-container {
  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.fslightbox-toolbar > .fslightbox-flex-centered:first-of-type {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.fslightbox-slide-number-container {
  z-index: 999;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex: 1 0 100%;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}

.filter-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;
  flex: 0 0 200px;
  flex-direction: column;
  padding: 0 20px;
}
.filter-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filter-area div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-color) !important;
}
.filter-area h3,
.filter-area h4 {
  color: var(--text-color)!important;
}

.filter-area label {
  display: flex;
  gap: 5px;
  align-items: center;
}
.filter-area input[type='checkbox'] {
  width: 16px;
  height: 16px;
}

.MuiSlider-markLabel {
  color: var(--text-color)!important;
}


.featured-collection-container {
  grid-template-columns: 1fr 1fr 1fr;

  h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .featured-collection-container {
    grid-template-columns: 1fr 1fr;

    h3 {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 600px) {
  .featured-collection-container {
    grid-template-columns: 1fr 1fr;

    h3 {
      font-size: 24px;
    }
  }
}

.featured-collection-container-places {
  grid-template-columns: 1fr 1fr 1fr;

  h3 {
    font-size: 24px;
  }
}

@media screen and (max-width: 899px) {
  .featured-collection-container-places {
    grid-template-columns: 1fr 1fr;

    h3 {
      font-size: 24px;
    }
  }
}

.banner-container {
  display: flex;
  width: 100%;
  gap: 20px;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}

.banner-item {
  padding: 20px;
  background-color: var(--sidebar-background);
  color: var(--text-color);
  border-radius: 6px;
  text-decoration: none; 
  p, h3 {
    margin: 0;
    padding: 10px;
  }
}

.no-mobile {
  @media screen and (max-width: 899px) {
    display: none;
  }
}

.banner-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  height: 100%;
  justify-content: center;
}

.discover-more {
  padding: 20px;
  background-color: var(--sidebar-background);
  color: var(--text-color);
  border-radius: 6px;
  text-decoration: none; 
  p, h3 {
    margin: 0;
    padding: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1;
    height: 100%;
    justify-content: center;
  }
}