:root {
  --background-color: #ffffff;
  --text-color: #171717;
  --navigation-background: #000;
  --sidebar-background: #f7f7f7;
  --checkbox-color: rgba(0, 0, 0, 0.6);
  --border-color: #6a6a6a
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #171717;
    --text-color: #ffffff;
    --navigation-background: transparent;
    --sidebar-background: #232323;
    --checkbox-color: #fff;
  }
}

body {
  margin: 0;
  font-family: "SUSE", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--background-color);
}

#root {
  height: 100vh;
  width: 100vw;
}

main {
  margin-top: 50px;
  min-height: calc(100vh - 110px);
}

@media screen and (max-width: 899px) {
  main {
    margin-top: 0px;
    min-height: 100vh;
  }
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin: auto;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding: 5px;
  }

  @media screen and (max-width: 899px) {
    padding: 0px;
  }
}

.App img {
  width: 100%; /* Ensure the image covers the full width */
  height: 100%; /* Ensure the image covers the full height */
  object-fit: cover; /* Ensures the image covers the container, even if it has to crop */
}

.fslightbox-toolbar,
.fslightbox-slide-btn {
  background: none !important;
}


